<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab>Detail Barang</v-tab>
          <v-tab>Status Upload</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <TableDetailOrder :dialog="dialog" :shipmentId="shipmentId"/>
          </v-tab-item>
          <v-tab-item>
            <TableStatusUpload :dialog="dialog" :shipmentId="shipmentId"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TableDetailOrder from './TableDetailOrder.vue';
import TableStatusUpload from './TableStatusUpload.vue';

export default {
  components: {
    TableStatusUpload,
    TableDetailOrder,
  },
  props: {
    shipmentId: Number,
  },
  data() {
    return {
      dialog: false,
      tab: 0,
    };
  },
};
</script>
