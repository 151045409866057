<template>
  <v-container>
    <v-btn
      v-if="isDisplayDetail"
      class="mb-5"
      color="secondary"
      elevation="2"
      outlined
      plain
      small
      tile
      @click="clickedCsvStatusList"
    >Csv status list</v-btn>
    <v-data-table
      v-if="!isDisplayDetail"
      :headers="headersStatusData"
      :items="itemsStatusData"
      :loading="isLoading"
      @click:row="handleCsvStatusRowClick"
      :options.sync="paginationHeader"
      :server-items-length="totalEntryHeader"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      class="elevation-1"
    ></v-data-table>
    <v-data-table
      v-else
      :headers="headersStatusDataDetail"
      :items="itemsStatusDataDetail"
      :loading="isLoading"
      :options.sync="paginationDetail"
      :server-items-length="totalEntryDetail"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';

export default {
  name: 'uploaded-csv-status-dialog',
  created() {
    this.fetchCsvStatusList();
  },
  data() {
    return {
      importType: 'SHIPMENT_DETAIL_ITEM',
      rowId: null,
      dialog: false,
      isDisplayDetail: false,
      isLoading: false,
      headers: [],
      headersStatusData: [
        { text: 'Diunggah Oleh', value: 'createdBy', cellClass: 'clickable' },
        { text: 'Diunggah Pada', value: 'timeUpload', cellClass: 'clickable' },
        { text: 'Status Proses', value: 'processStatus', cellClass: 'clickable' },
        { text: 'Sukses', value: 'successRecord', cellClass: 'clickable' },
        { text: 'Gagal', value: 'failedRecord', cellClass: 'clickable' },
      ],
      itemsStatusData: [],
      headersStatusDataDetail: [
        { text: 'Diunggah Oleh', value: 'createdBy' },
        { text: 'Diunggah Pada', value: 'timeUpload' },
        { text: 'Status Proses', value: 'importStatus' },
        { text: 'Deskripsi', value: 'description' },
      ],
      itemsStatusDataDetail: [],
      paginationHeader: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      paginationDetail: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalEntryHeader: 0,
      totalEntryDetail: 0,
      csvStatusListFilters: null,
    };
  },
  watch: {
    paginationHeader: {
      handler(oldVal, newVal) {
        const isSame = JSON.stringify(newVal) === JSON.stringify(oldVal);
        if (!this.rowId && !isSame) {
          this.fetchCsvStatusList();
        }
      },
      deep: true,
    },
    paginationDetail: {
      handler(oldVal, newVal) {
        const isSame = JSON.stringify(newVal) === JSON.stringify(oldVal);
        if (this.rowId && !isSame) {
          this.fetchCsvDetailList();
        }
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    clickedCsvStatusList() {
      this.rowId = null;
      this.isDisplayDetail = false;
      this.paginationDetail = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      };
    },
    handleCsvStatusRowClick(row) {
      if (!this.isDisplayDetail) {
        this.rowId = row.id;
        this.fetchCsvDetailList();
      }
    },
    fetchCsvStatusList(_page = null) {
      if (_page) this.paginationHeader.page = _page;
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.paginationHeader;
      let sort = 'timeUpload,DESC';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const filters = {
        page,
        itemsPerPage,
        sort,
        companyId,
      };
      this.isLoading = true;
      this.$_services.uploads
        .fetchUploadedCsvDataProcessStatus(filters, this.importType)
        .then((result) => {
          this.isDisplayDetail = false;
          this.itemsStatusData = result.data.contents.map((e) => (
            {
              id: e.id,
              createdBy: e.createdBy,
              timeUpload: `${this.dateFormat(e.timeUpload)} ${dayjs(e.timeUpload).format('HH:mm')}`,
              processStatus: e.processStatus,
              successRecord: e.successRecord,
              failedRecord: e.failedRecord,
            }
          ));
          this.totalEntryHeader = result.data.totalData;
        })
        .finally((i) => {
          this.isLoading = false;
        });
    },
    fetchCsvDetailList() {
      const {
        sortBy, sortDesc, itemsPerPage, page,
      } = this.paginationDetail;
      let sort = 'createdAt,DESC';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      const filters = {
        page,
        itemsPerPage,
        sort,
        id: this.rowId,
      };
      this.isLoading = true;
      this.$_services.uploads
        .fetchUploadedCsvDataProcessDetail(filters, this.importType)
        .then((result) => {
          this.isDisplayDetail = true;
          this.itemsStatusDataDetail = result.data.contents.map((e) => (
            {
              createdBy: e.createdBy,
              timeUpload: `${this.dateFormat(e.createdAt)} ${dayjs(e.createdAt).format('HH:mm')}`,
              importStatus: e.importStatus,
              description: `Baris ke-${e.rowData} ${e.description}`,
            }
          ));
          this.totalEntryDetail = result.data.totalData;
        }).finally((i) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-close{
  position: absolute;
  right: 0;
}
.style-status-failed {
  background-color: #fef0f0
}
.v-data-table.cursor-pointer tbody tr{
  cursor: pointer;
}
::v-deep {
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #aaa;
  }
  .theme--light.v-data-table
    .v-data-table-header
    th.sortable.active
    .v-data-table-header__icon {
    color: black !important;
  }
}
</style>
